.container {
    text-align: center;
    position: absolute;
    top: 40%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 90%;
    max-width: 420px;    
}

.heading {
    margin: 0 0 2rem 0;
    font-size: 5rem;
    font-weight: bold;
}

p {
    font-family: Arial;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.4000000059604645px;
    text-align: center;

}

.main-button {
    background: #000;
    padding: 10px 30px;
    color: #fff;
    cursor: pointer;
    margin-bottom: 1em;
    height: 57px;
    width :95%;
    max-width: 347px;
    border-radius: 40.5px;
    font-family: Arial;
    font-size: 21px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.4000000059604645px;
    text-align: center;

}

.code-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
}

.code {
    caret-color: transparent;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    border: 1px solid #D2D2D2;
    font-size: 30px;
    font-family: "Lato", sans-serif;
    width: 15%;
    max-width: 50px;
    padding: 10px;
    margin: 0px 5px;
    text-align: center;
    font-weight: 300;
}

.disabled {
    opacity: 0.4;
    cursor: not-allowed;
}

.error {
    color: red
}

.terms {
    font-family: Arial;
    font-size: 17px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.4000000059604645px;
    text-align: center;
    margin: auto 5%;
}

